* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}


/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;

  /* overflow-x: hidden; */

}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  font-weight: bolder;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primaryHome {
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: black; /* Cambiado a color negro */
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 100% solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  width: 25%; /* Ajusta el ancho según tus necesidades */
  height: 25%; /* Ajusta la altura según tus necesidades */
  margin-top: 10px;
}

.btn-primary {
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: black; /* Cambiado a color negro */
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 100% solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  width: 175px; /* Ajusta el ancho según tus necesidades */
  height: 35px; /* Ajusta la altura según tus necesidades */
  margin-left: 40%;
  margin-top: 10px;
}
a {
  color: var(--mainBlack); /* Cambia el color según tus necesidades */
  text-decoration: none;
}

a:hover {
  color: var(--primaryColor); /* Cambia el color del enlace al pasar el ratón */
}

.btn-primary{
  text-align: center;
}

.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 1;

}


.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: 0;
  overflow: auto;
  transition: var(--mainTransition);
  list-style-type: none;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: 100px;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */

/* Banner */
.banner {
  display: inline-block;
  background-image: url("https://res.cloudinary.com/duwgskhvh/image/upload/v1703767605/beynryrssacpkbtmehkv.jpg");
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: center; /* Centra la imagen de fondo tanto horizontal como verticalmente */
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  letter-spacing: var(--mainSpacing);
  width: 100%; /* Asegura que el banner ocupe el 100% del ancho de la pantalla */
  height: 100vh; /* Asegura que el banner ocupe el 100% de la altura de la pantalla */
  margin: 0; 
}
.bannerFed{
  display: inline-block;
  background-image: url("https://res.cloudinary.com/duwgskhvh/image/upload/v1703803648/Federacion/n9t4yeofxmorf1wlr4qn.jpg");
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: bottom; /* Centra la imagen de fondo tanto horizontal como verticalmente */
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  letter-spacing: var(--mainSpacing);
  width: 100%;
  height: 50vh;
  margin: 0; /* Elimina cualquier margen para que el banner alcance los bordes de la pantalla */
}

.BannerComodidades {
  display: inline-block;
  background-image: url("https://res.cloudinary.com/duwgskhvh/image/upload/v1703767325/ComodidadesCompress/ktlneivlyo2bzacllowl.jpg");
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: center; /* Centra la imagen de fondo tanto horizontal como verticalmente */
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  letter-spacing: var(--mainSpacing);
  /* width: 100%;
  height: 100vh;  */
  margin: 0; /* Elimina cualquier margen para que el banner alcance los bordes de la pantalla */
}


.bannerDepartamentos {
  display: inline-block;
  background-image: url("https://res.cloudinary.com/duwgskhvh/image/upload/v1703767393/DepartamentosCompress/rwleuytaefladbcsebrl.jpg");
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: bottom; /* Centra la imagen de fondo tanto horizontal como verticalmente */
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  letter-spacing: var(--mainSpacing);
  /* width: 100%;
  height: 100vh;  */
  margin: 0; /* Elimina cualquier margen para que el banner alcance los bordes de la pantalla */
}


.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 1rem 0;
}
.services {
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
  color: var(--mainBlack);
}

/* Comodidades */

.comodidades {
  padding: 1rem 0;
}

.comodidades-container {
  display: grid;
  margin: auto; 
  /* gap: auto; */
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.comodidades-list,
.comodidades-slider {
  width: 80%; /* Ajusta el ancho del slider según sea necesario */
  margin: auto;
  padding: 10px;
}


.comodidades-slider h2 {
  text-align: center;
}

.comodidades-list ul {
  list-style: none;
  padding: 0;
}

.comodidades-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comodidades-list li svg {
  margin-right: 10px;
  font-size: 1.5rem;
}

.comodidades-slider img {
  width: 100%;
  height: auto;
}




@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}
.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  background: var(--mainBlack);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  color: #222222;
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomList */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomList */
/* rooms filter */
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms filter */

/* footer style */
.footer-container{
  display: grid;
  margin: auto; 
  /* gap: auto; */
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background-color: #222; 
}
.footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #222;
}
.footer-left{
  text-align: left;
  margin-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #222;
}
.footer h2,
p {
  color: white;
}
.footer a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.footer a:hover {
  color: red;
  text-decoration: underline;
  transition: var(--mainTransition);
  -webkit-transition: var(--mainTransition);
  -moz-transition: var(--mainTransition);
  -ms-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
}


.comodidades-gallery {
  display: grid;
  margin: auto;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px; /* Espacio entre las imágenes */
}

.gallery-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}


/* Agrega este estilo a tu archivo CSS */
.comodidades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px; /* Espacio entre las características */
}

.comodidad-item {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.comodidad-item h5 {
  margin: 0;
}